import React from 'react'
import { MainWrapper } from '../components'

function AboutPage() {
    return (
        <MainWrapper>
            <div className='about'>
                <div className='abt-sec'>
                    <h2>About Benapad</h2>
                    <p className='hero-subtitle'>
                        Benapad is a general consultancy and legal firm that specializes in a wide range of advisory and heavy legal-related services.
                    </p>
                </div>
                <div className='abt-sec'>
                    <h2>Our Vision</h2>
                    <p className='hero-subtitle'>
                        Excelling to be the leader and the first point of call in all operational areas of private sector gold exports and trading, property acquisitions, shipement and legal-related consultations.
                    </p>
                </div>
                <div className='abt-sec'>
                    <h2>Our Mission</h2>
                    <p className='hero-subtitle'>
                        To effectively provide support services to customers across the country and enable them to achive whatever they want in an easier manner through the services of our expedient, diligent, experienced and dedicated staff.
                    </p>
                </div>
                <div className='abt-sec'>
                    <h2>Our Mission</h2>
                    <p className='hero-subtitle'>
                        <p>
                            <b>CLIENT CENTRIC: </b>
                            Benapad consultancy holds an inspired customer-centric culture at the heart its operations.

                        </p>

                        <p>
                            <b>INTEGRITY: </b>
                            Integrity is our hallmark, bread by the tenets of worthy principles, honesty and sincerity, to earn the trust and confidence of our clients in building long-term relationships.

                        </p>

                        <p>
                            <b>EXCELLENCE: </b>
                            We strive to be the best that we are, employing our capacities to reach out and fulfill our potential.
                        </p>
                    </p>
                </div>
            </div>
        </MainWrapper>
    )
}

export default AboutPage