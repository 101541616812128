import React from 'react'
import LogoSrc from '../../assets/svgs/logo.svg'
import LogoOnlySrc from '../../assets/svgs/logo-img.svg'


interface Props {
  size?: number;
  showText?: boolean;
  showGraphic?: boolean;
}

function Logo({ showGraphic = true, showText, size = 60 }: Props) {
  return (
    <div className='logo'>
      {showGraphic && <img style={{ height: 'auto', width: '159px' }} src={LogoSrc} alt='' />}
      {showText && <div style={{ fontSize: size / 2.5 }} className='logo-text'>Benapad</div>}
    </div>
  )
}

export function LogoOnly() {
  return (
    <div className='logo'>
      <img style={{ height: '40px', width: '40px' }} src={LogoOnlySrc} alt='' />
    </div>
  )
}

export default Logo