import React from 'react'
import { IconChat, IconEmail, IconPhone } from '../../../components'
import Button from '../../../components/button'

const size = 30
function ContactSection({title}: any) {
    const email = "Benapadcomp11@gmail.com";
    const phone = "+233244044395"

    const sendMail = () => {
        var yourMessage = "Hi Benapad, ";
        var subject = "Enquiry On ...";
        document.location.href = `mailto:${email}?subject=`
            + encodeURIComponent(subject)
            + "&body=" + encodeURIComponent(yourMessage);
    }

    return (
        <div id='contact' className='contact'>
            <div className="ct-content">
                <div className="ct-exp">
                    <h3 className="title">{title ? title : 'How do you want to reach us?'}</h3>
                    <div className="ct-con-lists">
                        <L onClick={sendMail} btnText={"Open mail"} icon={<IconEmail size={size} />} tit="Email" desc="Send an email for your enquiries." />
                        <L onClick={() => window.open('https://web.whatsapp.com/send?phone='+phone, "_blank")} btnText={"Send message"} icon={<IconChat size={size} />} tit="Chat" desc="Reach us on Whatsapp" />
                        <L onClick={() => document.location.href = "callto:"+phone} btnText={"Call now"} icon={<IconPhone size={size} />} tit="Call" desc="Give us a call" />
                    </div>
                    <div className='ct-con-cp-list'>
                        <div className='cp-li'>{email}</div>
                        <div className='cp-li'>Consultthem46@gmail.com</div>
                        <div className='cp-li'>{phone}</div>
                        <div className='cp-li'>+233201615218</div>
                    </div>
                </div>
            </div>
        </div>
    )
}

const L = ({ icon, tit, desc, btnText, onClick }: any) => {
    return (
        <div className="ct-list">
            <div className="hit">
                <div className="graphic">{icon}</div>
                <div className="tit">{tit}</div>
                <div className="desc">{desc}</div>
            </div>
            <div className="bt">
                <Button onClick={onClick} text={btnText} type='link' />
            </div>
        </div>
    )
}

export default ContactSection