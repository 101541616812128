import React from 'react'
import { WelcomeGraphicAnimation, IconLaw, IconCompany } from '../../../components';
import Avatar from '../../../components/avatar';
import ListItem from '../../../components/list';
import man from '../../../assets/images/profiles/man.jpeg';
import Button from '../../../components/button';

function Hero() {
  const viewMoreTestimonials = () => {
    const d = document.getElementById('testimonials');
    if (d) d.scrollIntoView({behavior: "smooth"})
  }
  return (
    <div className='hero-container'>
      <div className='hero-content'>
        <div className='hero-left'>
          <div className='hero-label'>
            <div>Legal &amp; Advice</div>
            <div className='icon'><IconLaw color='var(--red)' /></div>
          </div>
          <div className='hero-title'>
            <span>Legal</span> &amp; <span> Specialized</span> consultations at best!
          </div>
          <div className='hero-subtitle'>
            We Deliver exceptional legal services to you and specialized expertise in key practice areas.
          </div>
          <div className='hero-comments'>
            <ListItem className='testim' left={<Avatar url={man} />} 
              title={
                <>
                Client Am... - <span>5 stars</span>
                </>
              }
             subtitle="Burdens lost! And I had reduced time on researching in my small import and export business. Thanks to BCL!" />
            <div className='cm-divider'></div>
            <ListItem className='hh testim' left={<IconCompany size={45} />} title={<>Client Welln... - <span>4.5 stars</span></>}subtitle={"Buying lands in Ghana is like carrying a planet. With BCL, I carried just a bucket"} />
            <Button onClick={viewMoreTestimonials} text='View more testimonials...' type='link' />
          </div>
        </div>
        <div className='hero-right'>
          <div className='hero-graphic'>
            <WelcomeGraphicAnimation />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Hero