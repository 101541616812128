import React from 'react'
import {  AquirePropertiesGraphicAnimation, GoldImportGraphicAnimation, LandGraphicAnimation, LegalGraphicAnimation, ShipmentGraphicAnimation, TravelingGraphicAnimation } from '../../../components';
import { ReactComponent as Wave } from '../../../assets/svgs/wave.svg';


interface FeatureProps {
    icon: any;
    title: string;
    subtitle: string;
}

function Features() {
    const data = [
        {
            icon: <GoldImportGraphicAnimation />,
            title: 'Gold imports and exports',
            subtitle: "We trade in gold and offer other complimentary services in the gold import and export sector. We are second to none in this business and have location in all the major mining areas across the country."
        },
        {
            icon: <TravelingGraphicAnimation />,
            title: 'Traveling consultations',
            subtitle: "With Benapad,you are assured of competent specialist advice on all your travel documents and visa applications in relation to the best options most suited to your circumstances."
        },
        {
            icon: <LegalGraphicAnimation />,
            title: 'Legal consultation',
            subtitle: "We are here to solve most of your legal problems. Should it be anything, Benapad is here to help."
        },
        {
            icon: <LandGraphicAnimation />,
            title: 'Buy and sale of lands',
            subtitle: "Benapad provides advisory services to unlock property values through titling and secure rights of landholders. We have a healthy working relationship and collaboration with the Lands Commission, Traditional Authorities and other land sector agencies to meet your."
        },
        {
            icon: <AquirePropertiesGraphicAnimation />,
            title: 'Acquisitions of properties',
            subtitle: "We provides professional advice on property acquisition and registration. Real Estate, cars, lands and to name a few; we cover all these areas."
        },
        {
            icon: <ShipmentGraphicAnimation />,
            title: 'Shipment',
            subtitle: "Benapad's deep-seated expertise covers all shipping and offshore sectors providing clients with a combination of sector reports, forecasting models, vessel valuations and bespoke consultancy services."
        },
    ];
    return (
        <div className='section'>
            <div className='wave flip'>
                <div><Wave /></div>
            </div>
            <div id='services' className='features-wrapper'>
                <div className='fancy-feature-hero bottom'>
                    <div className='fancy-card'>
                        <h1 className='fn-ft-title'>Our <span>Areas</span> of <span>Consultation</span></h1>
                        <div className='fn-ft-subtitle'>We offer a range of consultation services to cover your needs at Benapad.</div>
                    </div>
                </div>
                <div className='fancy-feature-connector'>
                    <div className='fancy-feature-line'></div>
                    <div className='fancy-feature-dot'></div>
                </div>
                <div className='features'>
                    {data?.map((a, i) => <Feature key={i} icon={a.icon} title={a.title} subtitle={a.subtitle} />)}
                </div>
            </div>
        </div>
    )
}

function Feature({ icon, title, subtitle }: FeatureProps) {
    return (
        <div className='feature'>
            <div className='ft-graphic'>{icon}</div>
            <div className='ft-title'>{title}</div>
            <div className='ft-subtitle'>{subtitle}</div>
        </div>
    )
}


export default Features