import React from 'react'
import girl from '../../../assets/images/profiles/girl.jpeg';
import man from '../../../assets/images/profiles/man.jpeg';
import ListItem from '../../../components/list';
import Avatar from '../../../components/avatar';
import { IconCompany } from '../../../components';

function Testimonials() {
    const data = [
        {
            name: "Am",
            message: "Burdens lost! And I had reduced time on researching in my small import and export business. Thanks to BCL!",
            stars: 5,
            authorType: "man"
        },
        {
            name: "Jul",
            message: "Buying lands in Ghana is like carrying a planet. With BCL, I carried just a bucket",
            stars: 4.5,
            authorType: "woman"
        },
        {
            name: "Welln",
            message: "Burdens lost! And I had reduced time on researching in my small import and export business. Thanks to BCL!",
            stars: 4.5,
            authorType: "company"
        },
    ];
    return (
        <div className='testimonials'>
            <h3 id='testimonials'>Testimonials</h3>
            <div className='testi-list'>
                {
                    data.map((a, i) => {
                        return (
                            <ListItem key={i} className='testim bx' left={a.authorType === "company" ? <IconCompany size={45} /> : <Avatar url={a.authorType === "woman" ? girl : man} />}
                                title={
                                    <>
                                        Client {a.name}... - <span>{a.stars} stars</span>
                                    </>
                                }
                                subtitle={a.message} />
                        )
                    })
                }
            </div>
        </div>
    )
}

export default Testimonials