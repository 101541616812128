import React from 'react'
import { InlineWidget } from "react-calendly";
import { Footer, Header } from '../components';
import ContactSection from './welcome/components/ContactSection';

function AppointmentPage() {
    return (
        <div className='welcome'>
            <Header />
            <div className='appointment'>
                <div className='apt-title'>Schedule a time with us.</div>
                <div className='calendar'>
                    <InlineWidget url="https://calendly.com/benapad/30min" pageSettings={{ hideGdprBanner: true }} styles={{ height: 1200, background: 'var(--background)', width: 'calc(100% - 50px)', margin: "auto", borderRadius: "var(--radius)" }} />
                </div>
            </div>
            <ContactSection title="Like to contact us?" />
            <Footer />
        </div>
    )
}

export default AppointmentPage