import React from 'react'
import { IconCheck, StepsGraphicAnimation } from '../../../components'
import { PopupButton } from 'react-calendly'

function BusinessBrag() {
    return (
        <div className='brag'>
            <div className='br-title'>Every <span>step</span> you need to <span>know</span></div>
            <div className='br-subtitle'>It is easy with Benapad. We do not experiment and complicate things. We just grant you your needs.</div>
            <div className='br-button-content'>
                <PopupButton
                    url="https://calendly.com/benapad/30min"
                    /*
                     * react-calendly uses React's Portal feature (https://reactjs.org/docs/portals.html) to render the popup modal. As a result, you'll need to
                     * specify the rootElement property to ensure that the modal is inserted into the correct domNode.
                     */
                    rootElement={document.getElementById("root") as any}
                    text='Book an appointment now'
                    className='click button solid'
                    styles={{border: 0}}
                />
            </div>
            <div className='br-graphic'>
                <StepsGraphicAnimation />
            </div>
            <div className='br-steps'>
                <div className='br-step'>
                    <div className='br-step-icon'><IconCheck color='var(--green)' /></div>
                    <div className='br-step-text'>Book an appointment</div>
                </div>
                <div className='br-step'>
                    <div className='br-step-icon'><IconCheck color='var(--green)' /></div>
                    <div className='br-step-text'>Choose to meet in-person or online</div>
                </div>
                <div className='br-step'>
                    <div className='br-step-icon'><IconCheck color='var(--green)' /></div>
                    <div className='br-step-text'>We execute</div>
                </div>
            </div>
        </div>
    )
}

export default BusinessBrag