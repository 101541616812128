import React from 'react'
import Button from '../button'
import { IconFacebook, IconInstagram, IconLinkedIn, IconTwitter } from '../icons'
import Link from '../link'

const iconSize = 15;

function Footer() {
    function goTo(link: string) {
        window.open(link, '_blank')
    }

    return (
        <div className='footer'>
            <div className='ft-content'>
                <Link url='/about' text='About' />
                <div className='socials flex'>
                    <Button onClick={() => goTo('https://www.linkedin.com/company/Benapad')} fancy icon={<IconLinkedIn size={iconSize} />} />
                    <Button onClick={() => goTo('https://web.facebook.com/profile.php?id=Benapad')} fancy icon={<IconFacebook size={iconSize} />} />
                    <Button onClick={() => goTo('https://twitter.com/Benapad')} fancy icon={<IconTwitter size={iconSize} />}  />
                    <Button onClick={() => goTo('https://instagram.com/Benapad')} fancy icon={<IconInstagram size={iconSize} />} />
                </div>
            </div>
        </div>
    )
}

export default Footer