import React from 'react'
import { appendClass } from '../../utils';

export type TextTranform = "uppercase" | "capitalize" | "lowercase";

export type ButtonType = "tint" | "link" | "danger" | "solid" | "regular"


export interface ClickableProps {
    text?: string;
    fontStyle?: TextTranform;
    font?: "default" | "regular" | "bold" | "semi-bold" | "extreme-bold";
    type?: ButtonType;
    className?: string;
    padding?: string | number;
    icon?: any;
    hoverState?: "hover" | "nohover";
    background?: string;
    color?: string;
    disabled?: boolean;
    title?: string;
    fontSize?: string | number;
    style?: React.CSSProperties
}



export interface ClickProps extends ClickableProps {
    onClick?: React.MouseEventHandler<any> | undefined
}



 const Click: React.FC<ClickProps> = ({ onClick, style, title, text, disabled, type, fontStyle, font, className, padding, icon, hoverState, color, background, fontSize }) => {
    return (
        <div title={title} onClick={disabled ? undefined : onClick} style={{ fontSize, fontFamily: `var(--${font}-font)`, padding, color, background, textTransform: fontStyle, opacity: disabled ? 0.5 : 1, ...style }} className={`click ${appendClass(className, type, hoverState)}`}>
            {icon ? <div style={{ width: text ? 30 : '' }} className={`clk-icon`}>{icon}</div> : null}
            {text ? <div>{text}</div> : null}
        </div>
    )
}


export default Click








