import React from 'react'
import Lottie from "lottie-react";
import Welcome from "../../assets/lottie/consult2.json";
import Gold from "../../assets/lottie/gold.json";
import Travel from "../../assets/lottie/travel.json";
import Legal from "../../assets/lottie/legal.json";
import Shipment from "../../assets/lottie/ship.json";
import Land from "../../assets/lottie/land.json";
import Properties from "../../assets/lottie/aquire-properties.json";
import Steps from "../../assets/lottie/consult3.json";

export const WelcomeGraphicAnimation = () => {
    return <Lottie animationData={Welcome} />
}

export const GoldImportGraphicAnimation = () => {
    return <Lottie animationData={Gold} />
}

export const TravelingGraphicAnimation = () => {
    return <Lottie animationData={Travel} />
}

export const LegalGraphicAnimation = () => {
    return <Lottie animationData={Legal} />
}

export const LandGraphicAnimation = () => {
    return <Lottie animationData={Land} />
}

export const AquirePropertiesGraphicAnimation = () => {
    return <Lottie animationData={Properties} />
}

export const ShipmentGraphicAnimation = () => {
    return <Lottie animationData={Shipment} />
}

export const StepsGraphicAnimation = () => {
    return <Lottie animationData={Steps} />
}